// Import modulárních funkcí z Firebase SDK
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDLahq7HNeVlMIBGIRVDB8pLBCTfMIGMbg",
    authDomain: "optimaly-a478e.firebaseapp.com",
    projectId: "optimaly-a478e",
    storageBucket: "optimaly-a478e.appspot.com",
    messagingSenderId: "235104785033",
    appId: "1:235104785033:web:da035c77e69d54d0d16c58",
    measurementId: "G-5M7ZBY5FLL"
};

// Inicializace Firebase aplikace
const app = initializeApp(firebaseConfig);

// Inicializace služeb Firebase
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleAuthProvider = new GoogleAuthProvider();

export { auth, db, storage, googleAuthProvider };
