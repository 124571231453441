import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import './Post.css';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';

const Post = ({ postId, uid, place, time, image, description }) => {
  const [userDetails, setUserDetails] = useState({ displayName: '', photoURL: '' });

  useEffect(() => {
    // Asynchronní funkce pro načtení dat uživatele
    const fetchUserDetails = async () => {
      if(uid) {
        try {
          const userDocRef = doc(db, 'users', uid); // Reference na dokument uživatele
          const userDocSnap = await getDoc(userDocRef); // Načtení dokumentu

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserDetails({ displayName: userData.displayName, photoURL: userData.photoURL });
          } else {
            console.log("Uživatel nenalezen");
          }
        } catch (error) {
          console.error("Chyba při načítání dat uživatele:", error);
        }
      }
    };

    fetchUserDetails();
  }, [uid]); // Dependency array obsahuje uid, což znamená, že useEffect se spustí, když se uid změní.

  const handleDelete = async () => {
    if(window.confirm('Opravdu chcete smazat tento příspěvek?')) {
      try {
        const postDocRef = doc(db, 'post', postId); // Reference na dokument příspěvku
        await deleteDoc(postDocRef); // Smazání dokumentu
        console.log("Příspěvek byl úspěšně smazán");
      } catch (error) {
        console.error("Chyba při mazání příspěvku:", error);
      }
    }
  };

  return (
    <div className="post">
      <div className="post-header">
        {userDetails.photoURL && <img src={userDetails.photoURL} alt="profile" className="post-avatar" />}
        <div>
          <h3 className="post-username">{userDetails.displayName}</h3>
          <p className="post-time">{new Date(time).toLocaleString()}</p>
        </div>
        <button onClick={handleDelete} className="post-delete-btn">✕</button>
      </div>
      {image && <img src={image} alt="post-content" className="post-image" />}
      <div className="post-body">
        <p className="post-place">Location: {place}</p>
        <p className="post-description">{description}</p>
      </div>
    </div>
  );
};

export default Post;
