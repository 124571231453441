import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './AddContent.css';

const AddContent = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [place, setPlace] = useState('');
  const [time, setTime] = useState(new Date().toISOString());
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState('');

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPlace(`Lat: ${position.coords.latitude}, Long: ${position.coords.longitude}`);
      },
      (error) => {
        console.error('Chyba při získávání polohy:', error);
        setPlace('Nelze získat polohu');
      }
    );

    return () => unsubscribe();
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    if (image && currentUser) {
      const imageRef = ref(storage, `images/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      const postRef = collection(db, 'post');
      await addDoc(postRef, {
        uid: currentUser.uid,
        place: place,
        time: time,
        image: imageUrl,
        description: description
      })
      .then(() => {
        console.log('Dokument byl úspěšně přidán!');
        setPlace('');
        setTime(new Date().toISOString());
        setImage(null);
        setDescription('');
      })
      .catch((error) => {
        console.error('Došlo k chybě při přidávání dokumentu: ', error);
      });
    }
    setUploading(false);
  };

  return (
<div className="container">
  <div className='AddContent-container'>
    <h2 className='AddContent-header'>Přidat obsah</h2>
    <form onSubmit={handleSubmit} className='AddContent-form'>
      <div className='AddContent-flexGroup'>
        <label htmlFor='place' className='AddContent-label'>Místo:</label>
        <input type='text' id='place' value={place} onChange={(e) => setPlace(e.target.value)} className='AddContent-input' />
      </div>
      <div className='AddContent-flexGroup'>
        {/* <label htmlFor='time' className='AddContent-label'>Čas:</label> */}
        <input type='hidden' id='time' value={time} onChange={(e) => setTime(e.target.value)} className='AddContent-input' />
      </div>
      <div className='AddContent-flexGroup'>
        <label htmlFor='image' className='AddContent-label'>Obrázek:</label>
        <input type='file' id='image' onChange={(e) => setImage(e.target.files[0])} className='AddContent-input AddContent-inputFile' />
      </div>
      <div className='AddContent-flexGroup'>
        <label htmlFor='description' className='AddContent-label'>Popis:</label>
        <textarea id='description' value={description} onChange={(e) => setDescription(e.target.value)} className='AddContent-textarea' />
      </div>
      <button type='submit' disabled={uploading} className='AddContent-button'>{uploading ? 'Nahrávám...' : 'Přidat'}</button>
    </form>
  </div>
</div>

  );
}

export default AddContent;
