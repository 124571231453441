import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../components/UserContext';
import './Profile.css'; // Import stylů pro tuto komponentu

const Profile = () => {
    const { user, userData, handleSignOut } = useContext(UserContext);
    const navigate = useNavigate();

    // Přesměrujte uživatele, pokud není přihlášený
    useEffect(() => {
        if (!user) {
            navigate('/'); // Přesměrujte na hlavní stránku nebo na přihlašovací stránku
        }
    }, [user, navigate]);

    return (
        <div className='container'> {/* Přidána class s prefixem profile- */}
            {user ? (
                <div>
                    <h2 className='profile-header'>Vítejte, {userData.fullName}</h2> {/* Přidán prefix profile- */}
                    <p className='profile-info'>{user.email}</p> {/* Přidán prefix profile- */}
                    <p className='profile-info'>{userData.displayName}</p> {/* Přidán prefix profile- */}
                    <p className='profile-info'>{userData.photoURL && <img src={userData.photoURL} alt="Profile" className='profile-avatar' />}</p> {/* Přidány prefixy profile- */}
                    <p className='profile-info'>Created At: {userData.createdAt && userData.createdAt.toDate().toDateString()}</p> {/* Přidán prefix profile- */}
                    <p className='profile-info'>UID: {user.uid}</p> {/* Přidán prefix profile- */}
                    <button className='profile-logout-btn' onClick={handleSignOut}>Odhlásit se</button> {/* Přidán prefix profile- */}
                </div>
            ) : (
                <p>Načítám...</p> 
            )}
        </div>
    );
};

export default Profile;
