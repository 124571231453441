import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-footer">
      <div className="footer-content">
        © {new Date().getFullYear()} Optimaly. Všechna práva vyhrazena.
      </div>
    </footer>
  )
}

export default Footer;
