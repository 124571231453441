import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import Post from './Post';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import AddContent from './AddContent';

const Wall = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Reference na kolekci 'post' v Firestore
    const colRef = collection(db, 'post');

    // Vytvoření dotazu pro seřazení příspěvků podle času sestupně
    const q = query(colRef, orderBy('time', 'desc'));

    // Registrace posluchače na dotaz, který reaguje na změny v reálném čase
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postData);
    });

    // Funkce pro odregistrování posluchače
    return () => unsubscribe();
  }, []);

  return (
   
    <div className='container'>
       < AddContent />
      <div className='posts'>
        {posts.map(post => (
          <Post
            key={post.id}
            postId={post.id}
            uid={post.uid}
            place={post.place}
            time={post.time}
            image={post.image}
            description={post.description}
          />
        ))}
      </div>
    </div>
  )
}

export default Wall;
