import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import './SignUp.css';

const SignUp = () => {
    const navigate = useNavigate();
    const [log, setLog] = useState('');

    const auth = getAuth();
    const db = getFirestore();
    const googleAuthProvider = new GoogleAuthProvider();
 
    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const user = result.user;
            setLog('Přihlašuji uživatele pomocí Google');

            if (user) {
                setLog(`Uživatel přihlášen: ${user.displayName}`);

                const userRef = doc(db, 'users', user.uid);
                const docSnap = await getDoc(userRef);
                setLog('Kontrola, zda uživatel existuje v Firestore');

                if (!docSnap.exists()) {
                    await setDoc(userRef, {
                        fullName: user.displayName,
                        email: user.email,
                        createdAt: new Date(),
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                    });
                    setLog('Uživatel přidán do Firestore');
                } else {
                    setLog('Uživatel již existuje v Firestore');
                }
                navigate('/Profile'); // Přesměrování na /Profile
            }
        } catch (error) {
            setLog(`Chyba při přihlášení nebo vytváření uživatele: ${error.message}`);
        }
    };

    return (
        <div className='container'>
            <button onClick={signInWithGoogle} className="google-signin-btn">Přihlásit se pomocí Google</button>
            {log && <div className="log">{log}</div>}
        </div>
    );
};

export default SignUp;
