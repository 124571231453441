import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState({});

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const fetchUserData = async (userId) => {
            const userRef = doc(db, 'users', userId);
            try {
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    setUserData(docSnap.data());
                } else {
                    console.log("Uživatel nenalezen");
                }
            } catch (error) {
                console.error("Chyba při načítání dat uživatele:", error);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                fetchUserData(user.uid);
            } else {
                setUser(null);
                setUserData({});
            }
        });

        return () => unsubscribe();
    }, [auth, db]); // Přidány závislosti auth a db

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            console.log("Uživatel byl úspěšně odhlášen");
        } catch (error) {
            console.error("Chyba při odhlášení:", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, userData, handleSignOut }}>
            {children}
        </UserContext.Provider>
    );
};
