import { BrowserRouter, Routes, Route} from 'react-router-dom'
import Wall from './pages/Wall'
import AddContent from './pages/AddContent'
import SharedLayout from './pages/SharedLayout'
import Profile from './pages/Profile'
import './App.css'
import SignUp from './pages/SignUp'
import React from 'react';
import { UserProvider } from './components/UserContext';

const App = () => {
    return (
        <UserProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SharedLayout />}>
                        <Route index element={<Wall />} />
                        <Route path="/AddContent" element={<AddContent />} />
                        <Route path="/Profile" element={<Profile />} />
                        <Route path="/SignUp" element={<SignUp />} />
                    </Route>  
                </Routes>
            </BrowserRouter>
        </UserProvider>
    )
}

export default App;
