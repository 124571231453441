import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../components/UserContext'; // Ujistěte se, že importujete UserContext
import './Navbar.css';

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { user, userData } = useContext(UserContext); // Přístup k uživatelským datům z kontextu

  return (
<nav className="navbar-navbar">
  <div className="navbar-container">
    <div className="navbar-logo">
      <NavLink to="/" className="navbar-logo-link">Malíci na tripu</NavLink>
    </div>
    <button
      className="navbar-toggle"
      onClick={() => setIsNavExpanded(!isNavExpanded)}
      aria-expanded={isNavExpanded}
    >
      ☰
    </button>
    <ul className={`navbar-nav-links ${isNavExpanded ? 'expanded' : ''}`}>
      {/* <li><NavLink to="/" className={({ isActive }) => isActive ? "navbar-nav-item-active" : "navbar-nav-item"} onClick={() => setIsNavExpanded(false)}>Zeď</NavLink></li> */}
      {/* <li><NavLink to="/AddContent" className={({ isActive }) => isActive ? "navbar-nav-item-active" : "navbar-nav-item"} onClick={() => setIsNavExpanded(false)}>Přidat příspevěk</NavLink></li>     */}
      {user ? (
        <li className="navbar-user-info" onClick={() => setIsNavExpanded(false)}>
          <NavLink to="/Profile" className="navbar-nav-item">
            <div className="navbar-profile-container">
              <img src={userData.photoURL} alt="Profile" className="navbar-user-photo"/>
              <span>{userData.displayName || user.email}</span>
            </div>
          </NavLink>
        </li>
      ) : (
        <li><NavLink to="/SignUp" className={({ isActive }) => isActive ? "navbar-nav-item-active" : "navbar-nav-item"} onClick={() => setIsNavExpanded(false)}>Sign Up</NavLink></li>
      )}
    </ul>
  </div>
</nav>

  )
}

export default Navbar;
